
import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../utils/request";

import translate from "translate"

import $ from "jquery"

// import chat	from './modules/chat';
// import statistics from './modules/statistics';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    chat_number: 0,
    chatlist: [],
    loginStatus: window.localStorage.getItem("token") ? true : false,
    token: window.localStorage.getItem("token") ? window.localStorage.getItem("token").replace(/"/g, "") : '',
    app_url: window.localStorage.getItem("app_url") || "",
    moneyType: window.localStorage.getItem("moneyType") ? window.localStorage.getItem("moneyType") : "en",
    select: window.localStorage.getItem("select") ? window.localStorage.getItem("select") : "1",
    keyword: window.localStorage.getItem("keyword") ? window.localStorage.getItem("keyword") : "",
    addressDialog: false,
    loginDilaog: false,
    registeredDialog: false,
    forgetDialog: false,


    countryIndex: window.localStorage.getItem("countryIndex") || 0,
    country: window.localStorage.getItem("country") || "jp",
    userData: window.localStorage.getItem("userData") ? JSON.parse(window.localStorage.getItem("userData")) : [],
    address_list: window.localStorage.getItem("address_list") ? JSON.parse(window.localStorage.getItem("address_list")) : [],
    cartNumber: 0,
    laodingAdderess: false,
    addressData: {},
    kefuDialog: false,
    dialogZijin: false,
    loadingHome: true,
    init_data: {},

    loginyfxyConten: window.localStorage.getItem("loginyfxyConten") ? JSON.parse(window.localStorage.getItem("loginyfxyConten")) : "",
    loginyyszcConten: window.localStorage.getItem("loginyyszcConten") ? JSON.parse(window.localStorage.getItem("loginyyszcConten")) : "",
  },
  getters: {
  },
  mutations: {

    clearDiloag(state, data) {
      console.log(state)
      state.loginDilaog = false;
      state.registeredDialog = false;
      state.forgetDialog = false;
    }
  },
  actions: {
    getCart(store, data) {
      if (store.state.loginStatus) {
        axios({
          url: "/api/wanlshop/cart/synchro",
          method: "POST"

        }).then(res => {
          let num = 0;
          res.data.data.forEach(item => {
            num += item.number
          });
          store.state.cartNumber = num

        })
      }

    },

    getinit(store, data) {
      axios({
        url: "/api/wanlshop/common/init",
        method: "POST",
      }).then((res) => {
        if (res.data.code == 1) {

          store.state.init_data = res.data.data;
          let arr = store.state.init_data.modulesData.categoryModules;


          let lang = "en";

          switch (window.localStorage.getItem("country")) {
            case "en":
              lang = "english"
              break;
            case "kor":
              lang = "korean"
              break;

            case "zh-Hans":
              lang = "chinese_simplified"
              break;



            case "zh-Hant":
              lang = "chinese_traditional"
              break;

            case "vnm":
              lang = "vietnamese"
              break;

            case "jp":
              lang = "japanese"
              break;
            case "xby":
              lang = "spanish"
              break;
            case "ydl":
              lang = "italian"
              break;

            case "dy":
              lang = "deutsch"
              break;
            case "pty":
              lang = "portuguese"
              break;
            default:
              break;
          }

      
        let trans_arr = []

          store.state.init_data.modulesData.categoryModules.forEach(item => {
           
            trans_arr.push(item.name)
            
          })


              $.ajax({
            url: "https://api.translate.zvo.cn/translate.json",
            data: {
              from: "english",
              to: lang,
             text: JSON.stringify( trans_arr)
            },
            type: "POST",
            dataType: "json",
            success: function(data) {
              trans_arr =data.text

             
                // data = jQuery.parseJSON(data);  //dataType指明了返回数据为json类型，故不需要再反序列化

                store.state.init_data.modulesData.categoryModules.forEach((item,index) => {
           
                  item.name = trans_arr[index]
                  
                })
               
            }
        });


       

          window.localStorage.setItem("m_url", res.data.data.m_url)
          if (window.screen.width < 500) {


            window.location.href = window.localStorage.getItem("m_url");

          }
          setTimeout(() => {
            store.state.loadingHome = false
          }, 2000);


        }


      });

    },

    get_chatlist(store, data) {
      if (store.state.loginStatus) {
        axios({
          url: "/api/wanlshop/chat/lists",
          method: "POST"

        }).then(res => {
          store.state.chatlist = res.data.data;
          let num = 0;
          res.data.data.forEach(item => {
            num += item.count
          });
          store.state.chat_number = num


        })
      }

    },
    getUserinfo(store) {
      if (store.state.loginStatus) {
        axios({
          url: "/api/wanlshop/user/get_userinfo",
          method: "POST"

        }).then(res => {

          store.state.userData = res.data.data;
          window.localStorage.setItem("userData", JSON.stringify(res.data.data))


        })
      }
    },
    getaddress(store) {
      if (store.state.loginStatus) {
        store.state.laodingAdderess = true;
        axios({
          method: "POST",
          url: "/api/wanlshop/address/getaddress"
        }).then(res => {
          store.state.address_list = res.data.data.data;

          window.localStorage.setItem("address_list", JSON.stringify(res.data.data.data))
          store.state.laodingAdderess = false;

        })
      }
    },
    // getLoginTiaoKuan(store){
    //   axios({
    //     method:"POST",
    //     url:"/api/index/register_init"
    //   }).then(res => {
    //     console.log(res.data.data)
    //     // store.state.loginyfxyConten = res.data.data.yfxyConten;
    //     // store.state.loginyyszcConten = res.data.data.yszcConten;

    //     // window.localStorage.setItem("loginyfxyConten",store.state.loginyfxyConten)
    //     // window.localStorage.setItem("loginyyszcConten",store.state.loginyyszcConten)
    //   })
    // },
    // getAuth_info(){
    //   if( store.state.loginStatus){

    //   }
    // }
  },
  modules: {
    // chat, // 即时通讯
    // // common, // 系统初始化
    // // user, // 用户中心
    // // cart, // 购物车
    // statistics, // 系统更新
    // // update // 系统更新
  },
})
